<template>
    <div class="container">
        <loading-spinner
            v-if="showLoader"
            class="fixed"
        />
        <b-row>
            <sidebar />
            <b-col
                cols="12"
                md="9"
                class="mtd-40"
                :class="{ transparent: showLoader }"
            >
                <div>
                    <h4>{{ $t('page.myAddresses.heading') }}</h4>
                </div>
                <user-delivery-data id="delivery" />
                <user-billing-data id="billing" />
            </b-col>
        </b-row>
        <user-delivery-modal v-if="showDeliveryModal" />
        <user-billing-modal v-if="showBillingModal" />
        <user-company-modal v-if="showCompanyModal" />
        <delete-data-modal
            v-if="showDeleteDataModal"
            @deleteItem="deleteItem"
        />
    </div>
</template>

<script>
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapState } from 'vuex';

    import Sidebar from '@/components/page/Sidebar';

    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    import UserBillingData from './user/UserBillingData';
    import UserDeliveryData from './user/UserDeliveryData';
    const UserDeliveryModal = () => import('@/components/modals/UserDeliveryModal');
    const UserBillingModal = () => import('@/components/modals/UserBillingModal');
    const UserCompanyModal = () => import('@/components/modals/UserCompanyModal');
    const DeleteDataModal = () => import('@/components/modals/DeleteDataModal');
    export default {
        name: 'MyAddresses',
        components: {
            UserDeliveryModal,
            UserBillingModal,
            UserCompanyModal,
            DeleteDataModal,
            Sidebar,
            UserDeliveryData,
            UserBillingData,
            LoadingSpinner,
        },
        data() {
            return {
                name: '',
                email: '',
                loading: false,
                showDeliveryModal: false,
                showBillingModal: false,
                showCompanyModal: false,
                showDeleteDataModal: false,
            };
        },
        computed: {
            ...mapState('user', [
                'deliveryData',
                'billingData',
                'companyData',
                'modal',
            ]),
            lang() {
                return i18n.locale;
            },
            existData() {
                return (
                    Object.keys(this.deliveryData).length > 0 ||
                    Object.keys(this.billingData).length > 0 ||
                    Object.keys(this.companyData).length > 0
                );
            },
            showLoader() {
                return !this.existData && this.loading;
            },
        },
        methods: {
            ...mapActions('user', ['getUserData', 'deleteData']),
            async loadData() {
                this.loading = true;
                await this.getUserData();
                this.loading = false;
            },
            deleteItem() {
                this.deleteData({
                    id: this.modal.data.inputData.id,
                    type: this.modal.data.toDeleteType,
                });
            },
            loadRefresh() {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                }, 100);
            },
        },
        created() {
            this.loadData();
        },
        metaInfo() {
            return {
                title: this.$t('meta.myAccount.addressesTitle'),
                meta: [
                    {
                        name: 'robots',
                        content: 'noodp',
                    },
                    {
                        vmid: 'description',
                        name: 'description',
                        content: '',
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: '',
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: '',
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: '',
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: '',
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: '',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: '',
                    },
                ],
            };
        },
        watch: {
            lang: {
                handler() {
                    this.loadRefresh();
                },
                deep: true,
            },
            modal: {
                handler(modal) {
                    switch (modal.type) {
                    case 'delivery':
                        this.showDeliveryModal = modal.show;
                        break;
                    case 'billing':
                        this.showBillingModal = modal.show;
                        break;
                    case 'company':
                        this.showCompanyModal = modal.show;
                        break;
                    case 'delete':
                        this.showDeleteDataModal = modal.show;
                        break;
                    default:
                        break;
                    }
                },
                deep: true,
            },
        },
    };
</script>
<style lang="scss">
@import 'src/_scss/components/box.scss';
</style>
